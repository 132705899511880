import type { GetStaticPropsContext } from 'next'
import HomeRoute, { getHomeStaticProps } from '../features/home/route'
import { makeStaticProps } from '../lib/next'

export default HomeRoute

export async function getStaticProps(ctx: GetStaticPropsContext) {
  const { locale } = ctx
  const props = await getHomeStaticProps(locale)
  return makeStaticProps(props)
}
