import React from 'react'

interface ErrorBoundaryProps {
  children: React.ReactNode
  fallback: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true }
  }

  render() {
    const { children, fallback } = this.props
    const { hasError } = this.state

    if (hasError) {
      return fallback
    }
    return children
  }
}

export default ErrorBoundary

// eslint-disable-next-line
export function withErrorBoundary(Component: React.ComponentType<any>, fallback: React.ReactNode) {
  // eslint-disable-next-line
  return (props: any) => (
    <ErrorBoundary fallback={fallback}>
      <Component {...props} />
    </ErrorBoundary>
  )
}
