import { useQuery } from '@tanstack/react-query'
import { useFragment } from 'gql'
import type { ColorSchemeFragment, HomeQuery } from 'gql/graphql'
import queryHome from 'query/home'
import { colorSchemeFragment } from 'query/single-page/fragment'
import QueryKeys from './settings'

const useHomePage = (locale: string, onSuccess: (colorScheme: ColorSchemeFragment | null | undefined) => void) => {
  const homePageQuery = useQuery([QueryKeys.GetHomePage], () => queryHome(locale), {
    select: (data: HomeQuery) => data.pages[0],
    onSuccess: (data) => {
      // eslint-disable-next-line
      const homeColor = useFragment(colorSchemeFragment, data.colorScheme)
      onSuccess(homeColor)
    },
  })

  return homePageQuery
}

export default useHomePage
