import { LanguageCodes, type LanguageRoute } from '@/lib/i18n/settings'
import { client, requestHeaders } from 'query'
import { graphql } from '../../gql'

export const home = graphql(/* GraphQL */ `
  query home($lang: String) {
    pages(filter: { friendly_id: { _eq: "home" } }) {
      friendly_id
      translations(filter: { languages_code: { code: { _contains: $lang } } }) {
        title
        sections {
          ...PageSections
        }
        seo {
          ...SeoContent
        }
      }
      colorScheme: color_scheme {
        ...ColorScheme
      }
    }
  }
`)

async function queryHome(language?: string) {
  const lang = language as LanguageRoute
  const languageCode = LanguageCodes[lang]

  const data = await client.request(
    home,
    {
      lang: languageCode,
    },
    requestHeaders,
  )

  return data
}

export default queryHome
