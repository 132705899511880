import { dehydrate, QueryClient } from '@tanstack/react-query'
import { BadRequest } from '@tsed/exceptions'
import { QueryKeys } from 'lib/react-query'
import getPageMdxSources from 'lib/utils/getPageMdxSources'
import queryAnimalLotties from 'query/common/lotties'
import queryProducts from 'query/common/products'
import queryHome from 'query/home'
import { homePageConfig } from '../home.config'
import HomePage, { type THomePage } from '../pages/HomePage'

export default function HomeRoute(props: THomePage) {
  return <HomePage {...props} />
}

export const getHomeStaticProps = async (locale?: string) => {
  if (locale === undefined) {
    throw new BadRequest('locale is missing')
  }

  const { i18nNamespaces } = homePageConfig
  const queryClient = new QueryClient()

  const page = await queryClient.fetchQuery([QueryKeys.GetHomePage], () => queryHome(locale))
  const products = await queryClient.fetchQuery([QueryKeys.GetProductsListing], () => queryProducts(locale))
  const lotties = await queryClient.fetchQuery([QueryKeys.GetAnimalLotties], () => queryAnimalLotties(true, locale))

  const seo = { seo: page?.pages?.[0]?.translations?.[0]?.seo, pageTitle: page?.pages?.[0]?.translations?.[0]?.title }

  if (products && page && lotties == null) {
    return {
      notFound: true,
      i18nNamespaces,
      locale,
    }
  }

  const mdxSources = getPageMdxSources(page?.pages?.[0]?.translations?.[0]?.sections)

  return {
    i18nNamespaces,
    locale,
    props: {
      dehydratedState: dehydrate(queryClient),
      mdxSources,
      seo,
    },
  }
}
