import { strippedString } from '@okam/core-lib'
import type { TDirectusProps } from 'components/layout/MainLayout'
import { useFragment } from 'gql'
import type { SeoContentFragment } from 'gql/graphql'
import { seoFragment } from 'query/seo/fragment'

const formatTitle = (siteName: string, title: string) => {
  return `${strippedString(title)} - ${strippedString(siteName)}`
}

const useSeo = (pageSeo: SeoContentFragment | null | undefined, directusData: TDirectusProps, pageTitle = '') => {
  const globalSeo = useFragment(seoFragment, directusData?.settings?.translations?.[0]?.seo)

  return {
    ...globalSeo,
    ...pageSeo,
    title: formatTitle(globalSeo?.title ?? pageTitle, pageSeo?.title ?? pageTitle),
  }
}

export default useSeo
