import { isHex } from '@okam/core-lib/index'
import type { MDXRemoteSerializeResult } from 'next-mdx-remote'
import { MainLayout } from 'components/layout'
import type { TDirectusProps } from 'components/layout/MainLayout'
import 'twin.macro'
import MDXBlock from 'components/page-blocks/MDXBlock/MDXBlock'
import Seo from 'components/Seo'
import type { DocumentType } from 'gql'
import type { SeoContentFragment } from 'gql/graphql'
import useHomePage from 'lib/react-query/useHomePage'
import { useColor } from 'providers/Color'
import type { pageSectionsFragment } from 'query/single-page/fragment'

export interface THomePage {
  locale: string
  mdxSources: Array<MDXRemoteSerializeResult<Record<string, unknown>, Record<string, unknown>>>
  seo: { seo: SeoContentFragment | null | undefined; pageTitle: string }
  directusProps: TDirectusProps
}

const HomePage = ({ locale, mdxSources, seo, directusProps }: THomePage) => {
  const { setColorScheme } = useColor()

  const { data: homeData } = useHomePage(locale, (homeColorScheme) => {
    setColorScheme({
      title: homeColorScheme?.title || 'Test',
      accent: isHex(homeColorScheme?.accent) ? homeColorScheme?.accent ?? '#000000' : '#000000',
      background: isHex(homeColorScheme?.background) ? homeColorScheme?.background ?? '#000000' : '#000000',
    })
  })

  if (homeData?.translations?.[0]?.sections == null) {
    return null
  }

  const homeSections = homeData?.translations?.[0]?.sections?.map((section) => {
    const homeSection = section as DocumentType<typeof pageSectionsFragment>
    return homeSection
  })

  const mdxSections = homeSections?.map((section) => {
    return section?.item
  })

  return (
    <>
      <Seo directusData={directusProps} seo={seo.seo} pageTitle={seo.pageTitle} />
      <MainLayout footerProps={directusProps}>
        {mdxSections?.map((section, i) => {
          if (section?.type === 'block_mdx') {
            return <MDXBlock key={section.friendlyId} {...section} source={mdxSources[i]} />
          }
          return null
        })}
      </MainLayout>
    </>
  )
}

export default HomePage
