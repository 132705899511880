import { graphql } from 'gql'

export const seoFragment = graphql(/* GraphQL */ `
  fragment SeoContent on seo {
    title
    description
    thumbnail {
      id
      filename_download
      width
      height
      type
      description
    }
  }
`)
