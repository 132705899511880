import { MDXRemote } from 'next-mdx-remote'
import React, { type ForwardedRef } from 'react'
import { css } from 'twin.macro'
import ErrorBoundary from '@/components/ErrorBoundary'
import { mdxComponents } from '@/lib/next-mdx-remote'
import type { TMdxBlock } from '../types'

/* eslint-disable-next-line react/display-name */
const MDXBlock = React.forwardRef((props: TMdxBlock, ref: ForwardedRef<HTMLElement>) => {
  const { source, friendlyId } = props

  return (
    source && (
      <section
        css={css`
          overflow-x: clip;
        `}
        ref={ref}
        id={friendlyId ?? ''}
      >
        <ErrorBoundary fallback={null}>
          <MDXRemote {...source} components={mdxComponents} />
        </ErrorBoundary>
      </section>
    )
  )
})

export default MDXBlock
