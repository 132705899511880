import { graphql } from 'gql'

export const blockContentFragment = graphql(/* GraphQL */ `
  fragment BlockContent on block_content {
    title
    id
    content
    colors {
      ...ColorScheme
    }
  }
`)

export const blockFaqFragment = graphql(/* GraphQL */ `
  fragment BlockFaq on block_faq {
    qnaList: qna_list
    id
    title
    colors {
      ...ColorScheme
    }
  }
`)

export const blockMdxFragment = graphql(/* GraphQL */ `
  fragment BlockMdx on block_mdx {
    id
    title
    content
  }
`)

export const blockContestFragment = graphql(/* GraphQL */ `
  fragment BlockContest on block_contest {
    title
    content
    colors {
      ...ColorScheme
    }
    featured_image {
      filenameDownload: filename_download
      id
      width
      height
      description
    }
  }
`)

export const blockStoriesFragment = graphql(/* GraphQL */ `
  fragment BlockStories on block_stories {
    title
    colors {
      ...ColorScheme
    }
    stories {
      id
      storiesId: stories_id {
        id
        backgroundColor: background_color
        picture {
          id
          filenameDownload: filename_download
          description
        }
        footnote
        petName: pet_name
        content
      }
    }
  }
`)

export const pageSectionsFragment = graphql(/* GraphQL */ `
  fragment PageSections on pages_translations_sections {
    id
    item {
      type: __typename
      ... on block_content {
        shortTitle: short_title
        friendlyId: friendly_id
        isAnchored: is_anchored
        ...BlockContent
      }
      ... on block_faq {
        isAnchored: is_anchored
        shortTitle: short_title
        ...BlockFaq
      }
      ... on block_mdx {
        isAnchored: is_anchored
        shortTitle: short_title
        title: title
        friendlyId: friendly_id
        ...BlockMdx
      }
      ... on block_stories {
        isAnchored: is_anchored
        shortTitle: short_title
        friendlyId: friendly_id
        ...BlockStories
      }
      ... on block_contest {
        isAnchored: is_anchored
        shortTitle: short_title
        friendlyId: friendly_id
        ...BlockContest
      }
    }
  }
`)

export const colorSchemeFragment = graphql(/* GraphQL */ `
  fragment ColorScheme on color_schemes {
    title
    accent
    background
    outline
    backgroundMuted: background_muted
    accentMuted: accent_muted
  }
`)
