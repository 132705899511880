import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import type { SeoContentFragment } from 'gql/graphql'
import useSeo from 'helpers/use-seo'
import { toDirectusUrl } from 'lib/directus'
import type { TDirectusProps } from '../layout/MainLayout'

const SITE_DOMAIN = `https://${process.env.NEXT_PUBLIC_VERCEL_URL}` || 'http://localhost:3000'

const Seo = ({
  seo,
  pageTitle,
  directusData,
}: {
  seo: SeoContentFragment | null | undefined
  pageTitle: string
  directusData: TDirectusProps
}) => {
  const { title, description, thumbnail } = useSeo(seo ?? {}, directusData, pageTitle)
  const { locale } = useRouter()

  const thumbnailImg = {
    url: toDirectusUrl(thumbnail?.id, thumbnail?.filename_download) ?? '',
    alt: thumbnail?.description ?? undefined,
    width: thumbnail?.width ?? undefined,
    height: thumbnail?.height ?? undefined,
  }

  const openGraph = {
    url: SITE_DOMAIN,
    locale,
    type: 'website',
    title: title ?? pageTitle ?? undefined,
    description: description ?? undefined,
    images: !isEmpty(thumbnailImg.url) ? [thumbnailImg] : [],
    siteName: 'Petstory',
  }
  return (
    <NextSeo {...(title != null && { title })} {...(description != null && { description })} openGraph={openGraph} />
  )
}

export default Seo
