import type { MDXRemoteSerializeResult } from 'next-mdx-remote'
import { serialize } from 'next-mdx-remote/serialize'
import { useFragment } from 'gql'
import type { SinglePageQuery } from 'gql/graphql'
import { blockMdxFragment, pageSectionsFragment } from 'query/single-page/fragment'

type TPageSection = NonNullable<NonNullable<SinglePageQuery['pages'][number]['translations']>[number]>['sections']

const getPageMdxSources = (sections: TPageSection) => {
  const mdxSections = sections?.filter((section) => {
    const sectionTyped = useFragment(pageSectionsFragment, section)
    return sectionTyped?.item?.type === 'block_mdx'
  })

  const mdxSources: Array<MDXRemoteSerializeResult<Record<string, unknown>, Record<string, unknown>>> = []

  mdxSections?.forEach(async (section) => {
    const sectionTyped = useFragment(pageSectionsFragment, section)
    if (sectionTyped?.item?.type === 'block_mdx') {
      const mdxBlock = useFragment(blockMdxFragment, sectionTyped.item)
      const mdxBlockSource = await serialize(mdxBlock?.content ?? '')
      mdxSources.push(mdxBlockSource)
    }
  })

  return mdxSources
}

export default getPageMdxSources
